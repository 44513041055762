<template>
  <div class="homepage__actus">
    <div>
      <md-list class="homepage__list">
        <div class="block-header">
          {{ $t("homepage.actu-title") }}
        </div>

        <md-list-item v-for="actu of actus" :key="actu.nid">
          <div class="md-list-item-text">
            <img :src="getActuImageSrc(actu)" alt="" />

            <span class="news__title">
              {{ actu.title }}
            </span>

            <p class="news__description" v-html="getActuDescription(actu)" />

            <router-link class="news__link" :to="'/dashboard/news/' + actu.nid">
              {{ $t("homepage.actu-read-more") }}

              <md-icon class="md-primary--inverted news__link-icon">
                chevron_right
              </md-icon>
            </router-link>
          </div>
        </md-list-item>

        <md-list-item v-if="showMore">
          <md-button
            class="homepage__show-more-btn md-list-item"
            to="/dashboard/news/"
          >
            {{ $t("homepage.actu-view-more") }}
          </md-button>
        </md-list-item>
      </md-list>
    </div>
  </div>
</template>

<script>
import news from "@/model/news";
import get from "lodash/get";

export default {
  name: "HomePageActu",

  props: {},

  data() {
    return {
      actus: [],
      limit: 4,
      showMore: false,
    };
  },
  computed: {},

  watch: {},

  async mounted() {
    this.actus = await news.getActualites({
      page: 0,
      limit: this.limit,
    });
    this.showMore = true;
  },
  methods: {
    getActuImageSrc(actu) {
      if (actu.fields && actu.fields.field_image.und) {
        return (
          "https://expertplus.expertsa.net/sites/default/files/" +
          actu.fields.field_image.und[0].filename
        );
      } else {
        return "assets/images/default-image.jpg";
      }
    },

    getActuDescription(actu) {
      return get(actu, "body[0].safe_value", "");
    },
  },
};
</script>

<style scoped lang="scss">
.homepage__actus {
  ::-webkit-scrollbar {
    display: none;
  }
  &:hover {
    ::-webkit-scrollbar {
      display: initial;
    }
    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
  .homepage__list {
    overflow: auto;
    padding: 35px 29px 35px 29px;
    .block-header {
      padding: 0 16px;
      display: flex;
      align-items: center;
      font-family: var(--font-medium);
      font-size: 24px !important;
    }
    .md-list-item {
      margin-bottom: 20px;
      .news__title {
        font-family: var(--font-medium);
        margin: 5px 0;
        font-size: 18px !important;
      }
      .md-button {
        height: 100%;
        width: 100%;
        margin: 0;
        min-height: 48px;
        display: flex;
        align-items: center;
      }
      .news__link{
        line-height: 25px;
        font-family: var(--font-medium);
      }
      .news__description{
        font-family: var(--font);
      }
    }
  }
  
}

</style>
