<template lang="html">
  <section :class="homepagesmallcardClass" class="homepage__multitasks-card">
    <v-card outlined>
      <v-card-title>
        <slot>
          <MeepIconSettingCompanies class="app-icon" />
        </slot>
        <p>{{ title }}</p>
      </v-card-title>
      <div class="homepage__multitasks-card-buttons">
        <md-button
          v-for="(action, key) in actions"
          :key="key"
          :to="action.link"
          class="md-secondary md-raised"
          :class="action.class"
          @click="action.click ? action.click() : null"
          >{{ action.title }}
        </md-button>
      </div>
      <div v-if="isSecondCard" class="card-second">
        <v-card-title class="sub-title">
          <p>{{ title2 }}</p>
        </v-card-title>
        <div class="homepage__multitasks-card-buttons">
          <md-button
            v-for="(action, key) in actions2"
            :key="key"
            :to="action.link"
            class="md-secondary md-raised"
            :class="action.class"
            @click="action.click ? action.click() : null"
            >{{ action.title }}
          </md-button>
        </div>
      </div>
    </v-card>
  </section>
</template>

<script>
import MeepIconSettingCompanies from "@/components/icons/MeepIconSettingCompanies.vue";
import { mapGetters } from "vuex";

export default {
  name: "HomepageSmallCard",
  components: { MeepIconSettingCompanies },
  props: {
    title: {
      type: String,
      default: "Paramétrage",
    },
    actions: {
      type: Array,
      default: () => {
        return [
          { title: "fqfqwfqw", link: "#", class: "" },
          { title: "action1", link: "#", class: "" },
          { title: "action2", link: "#", class: "" },
        ];
      },
    },
    isSecondCard: {
      type: Boolean,
      default: false,
    },
    title2: {
      type: String,
      default: "",
    },
    actions2: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isJEP", "isCE", "isJEPA", "isMEEP", "isLCM", "isGC"]),
    homepagesmallcardClass() {
      return {
        "homepagesmallcard-jep": this.isJEP,
        "homepagesmallcard-ce": this.isCE,
        "homepage__multitasks-card--jepa": this.isJEPA,
        "homepagesmallcard-meep": this.isMEEP,
        "homepagesmallcard-lcm": this.isLCM,
        "homepagesmallcard-gc": this.isGC,
      };
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

.homepage__multitasks-card {
  width: 100%;

  .v-card {
    border-radius: 27px;
    padding: toRem(22) toRem(34);
    @include for-lg {
      padding: toRem(34);
    }

    &__title {
      font-family: var(--font-extrabold);
      font-size: toRem(15);
      padding: 0;
      margin-bottom: 16px;

      @include for-lg {
        font-size: toRem(27);
      }

      .app-icon,
      .app-icon-stroke {
        width: 26px;
        height: 26px;
        margin-right: 8px;
      }
    }

    .md-button {
      width: 100%;
      font-family: var(--font);
      text-transform: none;
      font-size: 17px;
      margin: 6px 8px;
    }
  }

  &-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .md-button.md-secondary {
      min-width: unset;
      font-size: toRem(12);

      @include for-lg {
        font-size: toRem(17);
      }
    }
  }

  &--jepa {
    .v-card__title {
      font-family: var(--font-medium);

      letter-spacing: -0.6px;
    }
    .sub-title {
      margin-top: 36px;
    }

    .homepage__multitasks-card-buttons {
      .md-button {
        font-family: var(--font-x) !important;
      }
      .client-jepa {
        height: 110px;
        white-space: normal;
        .md-ripple {
          padding: 0 64px;
        }
      }
    }
  }
  .card-second {
    .md-ripple {
      justify-content: flex-start;
      padding-left: 43px;
    }
  }
}

.homepagesmallcard-lcm {
  .v-card {
    &__title {
      font-family: var(--font-medium);
      font-weight: bold;
    }

    .md-button {
      font-family: var(--font-x) !important;
      font-size: toRem(12);
      @include for-lg {
        font-size: toRem(19);
      }
    }
  }
}

.homepagesmallcard-gc {
  .v-card {
    &__title {
      font-family: var(--font-medium);
      font-weight: bold;
    }

    .md-button {
      font-family: var(--font-x) !important;
      font-size: toRem(12);
      @include for-lg {
        font-size: toRem(19);
      }
    }
  }
}
</style>
