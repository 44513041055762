<template>
  <div class="homepage__task">
    <div>
      <md-list class="homepage__list">
        <div class="block-header title-task">
          {{ $t("homepage.tasks") }}
        </div>

        <md-list-item
          v-for="(task, index) in tasks"
          :key="index"
          class="md-tasks"
        >
          <router-link
            :to="'/dashboard/tasks/view/' + task.id"
            class="md-list-item-text task-title"
          >
            {{ task.title }}
          </router-link>

          <md-button class="md-icon-button" @click.stop="doneTask(task)">
            <md-icon> crop_square </md-icon>
          </md-button>
        </md-list-item>

        <p v-if="!tasks || tasks.length === 0" class="no-tasks">
          {{ $t("homepage.tasks-notasks") }}
        </p>
      </md-list>
    </div>
  </div>
</template>

<script>
import usersModel from "@/model/users";
import tasksModel from "../../model/tasks";
import { mapGetters } from "vuex";

export default {
  name: "HomePageTasks",

  props: {},

  data() {
    return {
      unverifiedUsers: [],
      tasks: [],
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "isCollab", "isJEP"]),
  },

  watch: {},

  async mounted() {
    if (this.isAdmin || this.isCollab) {
      if (this.isAdmin) {
        this.unverifiedUsers = await usersModel.getAllUnverifiedUsers();
      }

      this.tasks = await tasksModel.getAllIncomplete();
    }
  },
  methods: {
    async doneTask(task) {
      try {
        // Task done
        task.status = true;

        await tasksModel.update(task);

        this.$toasted.global.AppSucces({
          message: "La tâche a bien été mises à jour",
        });

        this.tasks = this.tasks.filter((_task) => _task.id !== task.id);
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/_variable.scss";
.homepage__task {
  &.md-layout-item .md-list .md-list-item-content .md-list-item-text {
    min-height: initial;
  }
  .homepage__list {
    overflow: auto;
    padding: toRem(20) 0;
    @include for-lg{
      padding: toRem(35) 0;
    }
    .title-task {
      display: flex;
      align-items: center;
      font-family: var(--font-medium);
      font-size: 24px;
      flex-direction: row;
      min-height: 40px;
      padding: 0 45px;
      font-size: 24px;
    }
    .no-tasks {
      font-size: 17px;
      font-weight: bold;
      padding: 0 45px;
      font-weight: 400;
    }
  }
}
</style>
