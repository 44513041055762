import fetch from '../services/fetch';
import store from '../store';

export default {
	get,
	getAll,
	create,
	update,
	remove,
	getToday,
	getLastMonth,
	getTrashed,
	putInTrash,
	restore
};

function get(id) {
	return fetch('api/news/' + id, {
		method: 'get',
		headers: {'Authorization': store.state.token},
	});
}

function getTrashed() {
	return fetch("api/news/get-trashed", {
		method  : "post",
		headers : {
			"Authorization" : store.state.token,
		},
	});
}

function putInTrash(id) {
	return fetch("api/news/put-in-trash", {
		method  : "post",
		headers : {
			"Authorization" : store.state.token,
			"Content-Type"  : "application/json",
		},
		body    : JSON.stringify({ id : id }),
	});
}

function restore(id) {
	return fetch("api/news/restore", {
		method  : "post",
		headers : {
			"Authorization" : store.state.token,
			"Content-Type"  : "application/json",
		},
		body    : JSON.stringify({ id : id }),
	});
}

function getAll(params) {

	let queryParams = '';

	if (params) {
		const sortType = params.type || 'desc';
		queryParams += `?sort=${params.sort}&sortType=${sortType}`;
	}

	return fetch('api/news' + queryParams, {
		method: 'get',
		headers: {'Authorization': store.state.token},
	});
}

function getToday(params) {
	let queryParams = '';
	if (params) {
		const sortType = params.type || 'desc';
		queryParams += `?sort=${params.sort}&sortType=${sortType}`;
	}

	return fetch('api/news/today' + queryParams, {
		method: 'get',
		headers: {'Authorization': store.state.token},
	});
}

function getLastMonth(params) {
	let queryParams = '';
	if (params) {
		const sortType = params.type || 'desc';
		queryParams += `?sort=${params.sort}&sortType=${sortType}`;
	}

	return fetch('api/news/last-month' + queryParams, {
		method: 'get',
		headers: {'Authorization': store.state.token},
	});
}

function create(news) {
	return fetch('api/news', {
		method: 'post',
		headers: {
			'Authorization': store.state.token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(news)
	});
}
function update(news) {
   return fetch('api/news/' + news.id, {
      method: 'put',
      headers: {
         'Authorization': store.state.token,
         'Content-Type': 'application/json'
      },
      body: JSON.stringify(news),
   });
}

function remove(id) {
	return fetch('api/news/' + id, {
		method: 'delete',
		headers: {'Authorization': store.state.token},
	});
}
