import fetch from "../services/fetch";
import store from "../store";

export default {
  get,
  getAll,
  getAllComplete,
  getAllIncomplete,
  getAllIncompleteByCompany,
  getCollabs,
  create,
  update,
  remove,
};

function get(id) {
  return fetch("api/tasks/" + id, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getAll(params) {
  return fetch("api/tasks", {
    method: "get",
    headers: { Authorization: store.state.token },
    params: params,
  });
}

function getAllComplete(params) {
  return fetch("api/tasks/complete", {
    method: "get",
    headers: { Authorization: store.state.token },
    params: params,
  });
}

function getAllIncomplete(params) {
  return fetch("api/tasks/incomplete", {
    method: "get",
    headers: { Authorization: store.state.token },
    params: params,
  });
}

function getAllIncompleteByCompany(params) {
  return fetch("api/tasks/incomplete-by-company/", {
    method: "get",
    headers: { Authorization: store.state.token },
    params: params,
  });
}

function getCollabs() {
  return fetch("api/users/get-collabs/", {
    method: "post",
    headers: { Authorization: store.state.token },
  });
}

function create(task) {
  return fetch("api/tasks", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(task),
  });
}

function update(task) {
  return fetch("api/tasks/" + task.id, {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(task),
  });
}

function remove(id) {
  return fetch("api/tasks/" + id, {
    method: "delete",
    headers: { Authorization: store.state.token },
  });
}
