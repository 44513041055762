<template>
  <md-list-item class="recipient" @click="$emit('show-chat-detail', chatId)">
    <div class="recipient__btn">
      <div class="homepage__red-cricle">
        <MeepIconHomepageChat class="app-icon" />
      </div>

      <div class="recipient__information">
        <div class="recipient__text">
          <p class="recipient__name">
            {{ name }}
          </p>

          <p
            v-if="lastMessage"
            class="recipient__last-message-at"
            v-html="$$filters.formatTimestampInChat(lastMessage.created_at)"
          />
        </div>

        <p
          v-if="lastMessage.content"
          class="recipient__last-message"
          :class="{ unread: unreadCountNumber > 0 }"
        >
          {{ lastSender }}: {{ lastMessageContent }}
        </p>
      </div>
    </div>
  </md-list-item>
</template>

<script>
import MeepIconHomepageChat from "../../components/icons/MeepIconHomepageChat";
import { mapGetters } from "vuex";

export default {
  name: "HomePageRecipient",

  components: {
    MeepIconHomepageChat,
  },

  props: {
    chatId: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    lastMessage: {
      type: Object,
      default: () => null,
    },

    unreadCount: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters(["self"]),

    lastSender() {
      if (!this.lastMessage) {
        return "";
      }
      return this.lastMessage.id === this.self.id
        ? "Vous"
        : `${this.lastMessage.firstname} ${this.lastMessage.lastname}`;
    },

    lastMessageContent() {
      if (!this.lastMessage.content) {
        return "";
      }
      return this.lastMessage.content.length > 64
        ? `${this.lastMessage.content.slice(0, 60)}...`
        : this.lastMessage.content;
    },

    unreadCountNumber() {
      return Number.parseInt(this.unreadCount);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variable.scss";

#app .recipient {
  button {
    border: solid 3px #f2f5f7;
    border-radius: 10px;
    overflow: auto;
  }

  &__btn {
    width: 100%;
    padding: 15px 0;
    display: flex;
    align-items: center;

    .homepage__red-cricle {
      margin-left: 0;
      .app-icon {
        width: fit-content;
        height: toRem(15);
        width: toRem(15);

        @include for-lg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  &__icon {
    flex: 24px 0 0;
    margin-right: 20px;
  }

  &__information {
    flex: 80% 1 1;
    overflow: hidden;
  }

  &__text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__last-message {
    width: 100%;
    margin: 0;
    font-weight: 400;
    font-size: toRem(11);

    @include for-lg {
      font-size: toRem(17);
    }
  }

  &__name {
    font-family: var(--font-extrabold);
    display: inline-block;
    margin: 0;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: toRem(12);

    @include for-lg {
      font-size: toRem(19);
    }
  }

  &__last-message-at {
    display: inline-block;
    margin: 0;
    font-size: toRem(5);
    font-family: var(--font-x);

    @include for-lg {
      font-size: toRem(10);
    }
  }

  &__last-message {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }
}
</style>
