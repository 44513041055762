<template>
  <modal class="select-modal" data-app>
    <div class="select-modal__header">
      <div
        :class="isGC ? 'app-logo-minified' : 'app-logo'"
        v-html="isGC ? logoMinified : logo"
      />
    </div>

    <div class="select-modal__content">
      <div class="select-modal__content-title">
        <slot><MeepIconSettings class="app-icon"/></slot>
        {{ title }}
      </div>
      <div class="select-modal__content-text">
        {{ text }}
      </div>
      <v-select
        v-model="selectedItem"
        :items="items"
        :item-text="property"
        :item-value="value"
        :label="label"
        :menu-props="{ bottom: true, offsetY: true }"
        solo
        background-color="#f2f5f7"
        class="select-modal__content-select"
      />
    </div>

    <div class="select-modal__actions">
      <md-button
        class="md-button md-primary--inverted md-theme-default"
        @click="$emit('close')"
      >
        {{ cancel }}
      </md-button>
      <md-button class="md-secondary" @click="$emit('confirm', selectedItem)">{{
        confirm
      }}</md-button>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";
export default {
  name: "SelectModal",
  components: {
    MeepIconSettings,
  },
  props: {
    title: { type: String, default: "Cloud" },
    text: {
      type: String,
      default:
        "Sélectionnez l’entreprise chez qui vous souhaitez déposer un fichier.",
    },
    cancel: {
      type: String,
      default: "ANNULER",
    },
    confirm: { type: String, default: "SUIVANT" },
    label: {
      type: String,
      default: "Entreprise",
    },
    items: {
      type: Array,
      default: () => [],
    },
    property: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedItem: "",
    };
  },
  computed: {
    ...mapGetters(["logo", "logoMinified", "isGC"]),
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
.select-modal {
  .modal-container {
    min-width: 50%;
    min-height: 400px;
    border-radius: 27px;
    padding: 10px 64px 60px 38px;
  }

  &__header {
    margin-bottom: 10px;

    .app-logo {
      svg {
        width: 105px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      font-family: var(--font-extrabold);
      font-size: var(--modal-title-size-lg);
      margin-bottom: 18px;
      @include for-lg{
        font-size: var(--modal-title-size-xl);
      }
      .app-icon {
        width: var(--modal-icon-size-lg);
        height: var(--modal-icon-size-lg);
        margin-right: 5px;
        @include for-lg{
          width: var(--modal-icon-size-lg);
          height: var(--modal-icon-size-lg);
        }
      }
    }

    &-text {
      margin-bottom: 38px;
      font-family: var(--font-bold);
      font-size: toRem(14);
      @include for-lg{
        font-size: toRem(22);
      }
    }

    &-select {
      width: 100%;
      .v-input__control {
        .v-input__slot {
          box-shadow: none;
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
