<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.8 28.976"><path d="M9.525 27.841a.609.609 0 01-.13.044 1.151 1.151 0 00.371-.153z"/><path d="M33.007.678a2.489 2.489 0 00-2.559-.527l-28.8 10.428a2.5 2.5 0 00-.339 4.546l6.616 3.582v8a1.19 1.19 0 00.889 1.161 1.2 1.2 0 00.581.015.6.6 0 00.13-.044l.241-.109 7.063-3.194 6.1 4.023a2.5 2.5 0 003.773-1.386l6.993-23.975a2.487 2.487 0 00-.688-2.52zM11.094 25.723l2.091-3.584 2.374 1.565zm21.37-22.882l-6.993 23.975a1.214 1.214 0 01-1.834.674l-6.37-4.2-.044-.03-3.251-2.142 13.4-13.848a1.116 1.116 0 00-1.444-1.69l-14.67 10.3a.642.642 0 10.737 1.05l13.232-9.295L12.513 20.78l-.009.01-.014.016a.666.666 0 00-.068.094c-.008.011-.013.022-.019.033l-3.195 5.476v-8.088a.643.643 0 00-.337-.565L1.92 13.992a1.214 1.214 0 01.165-2.21l28.8-10.428a1.214 1.214 0 011.58 1.482z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconSend'
  };
</script>
